<template>
  <div class="parent-container">
    <div v-if="hasSysAdminPermission || currentCompany" class="manager-container" v-loading="dataLoading">
      <div class="left" v-if="hasSysAdminPermission">
        <div class="title">
          <base-block-title
            :title="$t('company')"
            :sub-title="filteredCompanies.length"
          >
          </base-block-title>
        </div>
        <div class="search-bar">
          <el-input
            class="round-input"
            placeholder="Search Company Name"
            v-model="searchCompanyInput"
          >
          </el-input>
        </div>
        <div class="content">
          <company-item
            v-for="item in filteredCompanies"
            :key="item.id"
            :company="item"
            :active="selectedCompany && item.id === selectedCompany.id"
            @click.native="handleAdminSelectCompany(item)"
          >
          </company-item>
        </div>
      </div>
      <div class="right">
        <div class="div-main-title">
          <h1>Cargo Officers Management</h1>
          <div class="div-main-action">
            <el-input class="round-input margin-right-10" style="width:300px" placeholder="Search By Name or Email" v-model="cargoOfficerSearchValue"
              @keypress.enter.native="getCargoOfficers(selectedCompany.id)">
              <el-button type="primary" slot="append" icon="el-icon-search" @click="getCargoOfficers(selectedCompany.id)"></el-button>
            </el-input>
            <el-button v-if="hasPermission($permissionFunctionCode.ADD)" round type="primary" @click="handleOpenCargoOfficerDialog">Add Cargo Officer</el-button>
          </div>
        </div>
        <!--  width="100%" -->
        <el-table
          :data="cargoOfficers"
          border
          :header-cell-style="HEADER_CELL_STYLE"
          :cell-style="CELL_STYLE"
           width="100%"
        >
          <el-table-column type="index"> </el-table-column>
          <el-table-column
            label="Name"
            min-width="180px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Email"
            min-width="280px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.email }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Code"
            min-width="60px"
            show-overflow-tooltip
          >
            <template v-slot="scope">
              <div>{{ scope.row.code ? scope.row.code.replace(/./g, '*') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="Active" min-width="60px" show-overflow-tooltip>
            <template v-slot="scope">
              <el-switch
                :disabled="!scope.row.isOwner"
                v-model="scope.row.isActive"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="(value) => value ? activateCargoOfficerConfirmation(scope.row) : deactivateCargoOfficerConfirmation(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column min-width="240px"  label="Transferred To/From" show-overflow-tooltip>
            <template v-slot="scope">
              <div v-if="scope.row.charterId"  class="transfer-to-from">
                 <span  v-if="scope.row.isOwner"  class="arrow-icon"><img src="@/assets/images/action_icons/transfer_co_to.png" alt=""></span>
                <span  v-else class="arrow-icon"><img src="@/assets/images/action_icons/transfer_co_from.png" alt=""></span>
                <p :style="{color: scope.row.isOwner ? '#222BFF': '#FC7C05',fontWeight:'400',display:'block',fontSize:'14px'}" >{{scope.row.transferToOrFrom ? scope.row.transferToOrFrom : ''}}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50px" show-overflow-tooltip>
            <template v-slot="scope">
              <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
                <el-button @click="editCargoOfficer(scope.row)" type="primary" icon="el-icon-edit" circle :disabled="!scope.row.isOwner"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="50px" show-overflow-tooltip>
            <template v-slot="scope">
              <el-tooltip class="item" effect="dark" content="Resend Email" placement="top-start">
                <el-button v-if="scope.row.email" @click="sendEmailCargoOfficerConfirmation(scope.row)" type="info" icon="el-icon-message" circle :disabled="!scope.row.isOwner"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="50px" show-overflow-tooltip>
            <template v-slot="scope">
              <el-tooltip class="item" effect="dark" content="Reset Code" placement="top-start">
                <el-button v-if="scope.row.email" @click="resetCargoOfficerCodeConfirmation(scope.row)" type="danger" icon="el-icon-refresh" circle :disabled="!scope.row.isOwner"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="50px" show-overflow-tooltip>
            <template v-slot="scope">
              <div class="flex-between-row">
                <el-tooltip  v-if="scope.row.charterId == null" class="item" effect="dark" content="Transfer Cargo Officer" placement="top-end">
                  <el-button class="transfer-to"  size="small" circle>
                   <span  @click={showTransferCargoOfficerDialog(scope.row)}>
                      <img style="height:12px;width:12px; " src="@/assets/images/action_icons/transfer_action_icon.png" alt=""/>
                    </span>
                   </el-button>
                </el-tooltip>
                <el-tooltip  v-else class="item" effect="dark" content="Stop Current Transfer" placement="top-end">
                  <el-button  class="transfer-stop" @click={stopTransfer(scope.row)} size="small" icon="el-icon-finished" circle  :disabled="scope.row.charterId == selectedCompany.id"></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="div-main-list-page-table-pagination">
          <el-pagination background @size-change="handleCargoOfficerPageSizeChange" @current-change="handleCargoOfficerPageNumberChange"
            :page-size.sync="cargoOfficerPagination.pageSize" :page-sizes="[5, 10, 20, 50, 100]"
            :current-page="cargoOfficerPagination.pageNumber" layout="total, sizes, prev, pager, next, jumper"
            :total="cargoOfficerPagination.total">
          </el-pagination>
        </div>
      </div>
      <el-dialog
        v-loading="saveCargoOfficerLoading"
        class="el-dialog-save-cargo-officer"
        title="Save Cargo Officer"
        :visible.sync="dialogSaveCargoOfficer"
        width="35%"
        :before-close="handleCloseCargoOfficerDialog"
      >
        <el-form
          :model="cargoOfficerForm"
          :rules="rules"
          ref="cargoOfficerForm"
          label-position="top"
        >
          <div>
            <el-form-item
              label="Name"
              prop="name"
            >
              <el-input
                v-model="cargoOfficerForm.name">
              </el-input>
            </el-form-item>
            <el-form-item
              label="Email"
              prop="email"
            >
              <el-input
                v-model="cargoOfficerForm.email">
              </el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer">
          <el-button round type="primary" @click="saveCargoOfficer">
            Confirm
          </el-button>
          <el-button outline round @click="handleCloseCargoOfficerDialog">
            Cancel
          </el-button>
        </div>
      </el-dialog>
         <el-dialog
      :visible.sync="transferCargoOfficerDialogVisible"
      :close-on-click-modal="false"
      width="40%">

      <div style="margin-bottom:10px"> <span style="font-weight:400; margin-right:10px; font-size: 12"> {{$t('name')}}:</span> <span>{{selectedTransferCargoOfficer && selectedTransferCargoOfficer.name ? selectedTransferCargoOfficer.name : '-'}}</span> </div>
      <div style="margin-bottom:20px"> <span style="font-weight:400; margin-right:10px; font-size: 12">{{$t('email')}}:</span> <span>{{selectedTransferCargoOfficer && selectedTransferCargoOfficer.email ? selectedTransferCargoOfficer.email : '-'}}</span> </div>

      <el-form ref="transferShipForm">
        <div style=" width:500px;
          height: 20px;
          display:flex;
          flex-direction: row;
          justify-content: start;
          font-size: 14.5;
          font-weight: 500;"><span style="color:red">* </span> {{ $t('transferTo') }}</div>
        <el-form-item
          prop="transferTocargoOfficerorId"
          :rules="{ required: true, trigger: 'blur' }"
          >
          <el-select
            style="width:100%"
            v-model="transferFormData.organizationId"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a keyword"
            :remote-method="getTransferToCompanyOpts"
            :loading="selectOpstSearchLoading"
            >
            <el-option
              v-for="item in transferToCompanyOpst"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          @click="transferCargoOfficerDialogVisible = false">
          {{ $t('cancel') }}
        </el-button>
        <el-button
          type="primary"
          round
          @click="handleTransferOrTerminateCargoOfficer(true)">
         {{ $t('startTransfer') }}
        </el-button>
      </span>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE, GENERAL_EMAIL_RULE_REQ } from "@/constants.js";
import { mapState } from "vuex";
import { sortByAlphabetical } from "@/utils";

export default {
  name: "cargoOfficerManagement",
  data() {
    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: ["blur", "change"],
        },
      ],
      email: GENERAL_EMAIL_RULE_REQ
    };
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      rules,
      cargoOfficerPagination: {
        pageSize: 20,
        pageNumber: 1,
        total: null
      },
      cargoOfficers: [],
      cargoOfficerSearchValue: null,
      companies: [],
      cargoOfficerForm: {
        id: null,
        organizationId: null,
        name: null,
        email: null
      },
      dialogSaveCargoOfficer: false,
      dataLoading: false,
      saveCargoOfficerLoading: false,
      selectedCompany: null,
      searchCompanyInput: null,
      selectedTransferCargoOfficer: null,
      transferToCompanyOpst: [],
      transferFormData: {
        organizationId: null
      },
      transferCargoOfficerDialogVisible: false,
      selectOpstSearchLoading: false,
      transferToCompanyList: []
    };
  },
  computed: {
    ...mapState(["currentCompany", "permissions"]),
    companyOptions() {
      if (!this.selectedCompany) return [];
      return this.companies.filter(
        (c) =>
          c.id !== this.selectedCompany.id
      );
    },
    hasSysAdminPermission() {
      return this.permissions.hasSysAdminPermission;
    },
    filteredCompanies() {
      if (!this.searchCompanyInput) return this.companies
      return this.companies.filter(c => c.companyName.toLowerCase().includes(this.searchCompanyInput.toLowerCase())
      );
    },
  },
  mounted() {
    this.dataLoading = true;
    this.getOnlineCompanies().then(() => {
      this.selectedCompany = this.hasSysAdminPermission ? this.companies.find(() => true): this.currentCompany;
      this.getCargoOfficers(this.selectedCompany.id)
      this.dataLoading = false;
    });
  },
  methods: {
    sortByAlphabetical,
    async getCargoOfficers(companyId) {
      const encodedSearchValue = this.cargoOfficerSearchValue ? encodeURIComponent(this.cargoOfficerSearchValue) : null
      const filter = `&filter=organizationId:${companyId} or charterId:${companyId}` + (encodedSearchValue ? ` and (name ~'*${encodedSearchValue}*' or email ~'*${encodedSearchValue}*')` : '')
      const sort = '&sort=name asc'
      const res = await this.$request.get({
        url: `${this.$apis.cargoOfficer}?pageNumber=${this.cargoOfficerPagination.pageNumber - 1}&pageSize=${this.cargoOfficerPagination.pageSize}${filter}${sort}`,
      });

      if (res.code === 1000) {
        try {
          await this.getTransferToCompanyOpts('', true)
        } catch (error) {

        }

        this.cargoOfficers = res.data?.content || []
        this.cargoOfficerPagination.pageSize = res.data?.size || 20
        this.cargoOfficerPagination.pageNumber = res.data?.number + 1 || 1
        this.cargoOfficerPagination.total = res.data?.totalElements
        this.cargoOfficers.forEach(cargoOfficer => {
          if (cargoOfficer.charterId) {
            const isOwner = this.selectedCompany.id === cargoOfficer.organizationId
            const selectedCompanyId = (isOwner ? cargoOfficer.charterId : cargoOfficer.organizationId)
            const transferToOrFrom = this.transferToCompanyList.find(item => item.value === selectedCompanyId)
            cargoOfficer.transferToOrFrom = transferToOrFrom?.label
            cargoOfficer.isOwner = isOwner
          } else {
            cargoOfficer.isOwner = true
          }
        })
      }
    },
    async getOnlineCompanies() {
      const res = await this.$request.get({
        url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999&offline=false`
      });
      if (res?.code === 1000) {
        const filtered =
          res.data?.records.filter(
            (c) => c.companyName && c.companyName !== "noCompany"
          ) ?? [];
        this.companies = this.sortByAlphabetical(filtered, "companyName");
      }
    },
    handleOpenCargoOfficerDialog() {
      this.dialogSaveCargoOfficer = true;
    },
    handleCloseCargoOfficerDialog() {
      this.dialogSaveCargoOfficer = false;
      this.resetForm();
    },
    handleCargoOfficerPageSizeChange(size) {
      this.cargoOfficerPagination.pageSize = size
      this.getCargoOfficers(this.selectedCompany.id)
    },
    handleCargoOfficerPageNumberChange(number) {
      this.cargoOfficerPagination.pageNumber = number
      this.getCargoOfficers(this.selectedCompany.id)
    },
    editCargoOfficer(cargoOfficer) {
      this.cargoOfficerForm = cargoOfficer
      this.handleOpenCargoOfficerDialog()
    },
    saveCargoOfficer() {
      this.$refs.cargoOfficerForm.validate(async (valid) => {
        if (!valid) return;

        this.saveCargoOfficerLoading = true;

        try {
          const res = this.cargoOfficerForm.id ?
          await this.$request.put({
            url: this.$apis.cargoOfficer,
            data: {
              ...this.cargoOfficerForm,
            },
          })
          : await this.$request.post({
            url: this.$apis.cargoOfficer,
            data: {
              ...this.cargoOfficerForm,
              organizationId: this.selectedCompany.id,
            },
          })

          this.resetForm();
          this.getCargoOfficers(this.selectedCompany.id)
          this.dialogSaveCargoOfficer = false;
        }
        catch (e) {
          this.$message({
            type: "error",
            message: "Error Saving Cargo Officer",
          });
        }
        this.saveCargoOfficerLoading = false;
      });
    },
    resetForm() {
      this.cargoOfficerForm = {
        id: null,
        organizationId: null,
        name: null,
        email: null
      };

      this.$refs.cargoOfficerForm.resetFields();
    },
    async sendEmailCargoOfficer(cargoOfficerId) {
      this.dataLoading = true;
      const res = await this.$request.post({
        url: `${this.$apis.cargoOfficer}/${cargoOfficerId}/send_email`
      });

      if (res.code !== 1000) {
        this.dataLoading = false;
        return;
      }

      this.dataLoading = false;
    },
    async cargoOfficerActivation(cargoOfficerId, isActive) {
      this.dataLoading = true;
      const res = await this.$request.post({
        url: `${this.$apis.cargoOfficer}/${cargoOfficerId}/active/${isActive}`
      });

      if (res.code !== 1000) {
        this.dataLoading = false;
        return;
      }

      this.getCargoOfficers(this.selectedCompany.id)
      this.dataLoading = false;
    },
    async cargoOfficerCodeReset(cargoOfficerId) {
      this.dataLoading = true;
      const res = await this.$request.post({
        url: `${this.$apis.cargoOfficer}/${cargoOfficerId}/reset_code`
      });

      if (res.code !== 1000) {
        this.dataLoading = false;
        return;
      }

      this.getCargoOfficers(this.selectedCompany.id)
      this.dataLoading = false;
    },
    async sendEmailCargoOfficerConfirmation(cargoOfficer) {
      this.$confirm(
        `An email will be sent to the cargo officer (${cargoOfficer.name}). \nConfirm to send? `,
        "Send Email To Cargo Officer",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.sendEmailCargoOfficer(cargoOfficer.id);
          this.$message({
            type: "success",
            message: "Email Sent",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    },
    async activateCargoOfficerConfirmation(cargoOfficer) {
      this.$confirm(
        `The cargo officer (${cargoOfficer.name}) activated will be able to sign eBDN. \nConfirm to activate? `,
        "Activate Cargo Officer",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.cargoOfficerActivation(cargoOfficer.id, true);
          this.$message({
            type: "success",
            message: "Activated",
          });
        })
        .catch(() => {
          cargoOfficer.isActive = !cargoOfficer.isActive
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    },
    async deactivateCargoOfficerConfirmation(cargoOfficer) {
      this.$confirm(
        `The cargo officer (${cargoOfficer.name}) deactivated will no longer be able to sign eBDN. \nConfirm to deactivate? `,
        "Deactivate Cargo Officer",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.cargoOfficerActivation(cargoOfficer.id, false);
          this.$message({
            type: "success",
            message: "Deactivated",
          });
        })
        .catch(() => {
          cargoOfficer.isActive = !cargoOfficer.isActive
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    },
    async resetCargoOfficerCodeConfirmation(cargoOfficer) {
      this.$confirm(
        `The cargo officer (${cargoOfficer.name})'s code will be reset to a new code. \nConfirm to reset code? `,
        "Reset Cargo Officer Code",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
          center: true,
        }
      )
        .then(async () => {
          await this.cargoOfficerCodeReset(cargoOfficer.id);
          this.$message({
            type: "success",
            message: "Code Reset",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled",
          });
        });
    },
    async handleAdminSelectCompany(company) {
      this.selectedCompany = company;
      this.cargoOfficerPagination.pageNumber = 1
      await this.getCargoOfficers(this.selectedCompany.id);
    },
    handleAdminSearchCompany() {
      const key = this.searchCompanyKey;
      this.getCompanies().then(() => {
        if (this.isValidString(key) && this.companyList?.length > 0) {
          const companyListSearched = this.companyList.filter((d) =>
            d.companyName
              .toLowerCase()
              .includes(this.searchCompanyKey.toLowerCase())
          );
          this.companyList = companyListSearched;
          this.toggleSearchCompanyIcon = !this.toggleSearchCompanyIcon;
        } else {
          this.toggleSearchCompanyIcon = false;
        }
      });
    },
    /**
     * Transfer cargo officer 相关功能函数
     */
    showTransferCargoOfficerDialog (row) {
      this.transferCargoOfficerDialogVisible = true
      this.selectedTransferCargoOfficer = row
    },
    async handleTransferOrTerminateCargoOfficer (isStart) {
      const data = {
        cargoOfficerId: this.selectedTransferCargoOfficer?.id
      }
      const url = isStart ? this.$apis.cargoOfficerTransfer : this.$apis.cargoOfficerTerminate
      if (isStart) data.charterId = this.transferFormData.organizationId
      try {
        const res = await this.$request.post({
          url,
          data: [data]
        })
        if (res?.code === 1000) {
          // this.getCargoOfficers(this.selectedCompany.id)
        }
      } catch (e) {
        this.$message.error(`${isStart ? 'Transfer' : 'Terminate'} Cargo Officer Failed`)
      } finally {
        this.getCargoOfficers(this.selectedCompany.id)
        if (this.transferCargoOfficerDialogVisible) this.transferCargoOfficerDialogVisible = !this.transferCargoOfficerDialogVisible
      }
    },
    stopTransfer (row) {
      this.$confirm('Are you sure to stop current transfer?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.selectedTransferCargoOfficer = row
        this.handleTransferOrTerminateCargoOfficer(false)
      }).catch(() => {
      })
    },
    /**
     * Get Transfer To Company 相关功能函数
     * 1.only online mode Company
     * 2.can serch by server side
     */
    async getTransferToCompanyOpts (searchKey, isGetAll) {
      this.selectOpstSearchLoading = true
      const hasSearchKey = searchKey !== null && searchKey.trim() !== ''
      const pageSize = hasSearchKey || isGetAll ? '999' : '10'
      let url = `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=${pageSize}&offline=false`
      if (hasSearchKey) {
        url += `&companyName=${searchKey}`
      }
      try {
        const res = await this.$request.get({
          url
        })
        if (res?.code === 1000 && res.data?.records?.length > 0) {
          this.transferToCompanyOpst = res.data.records.filter(item => item.id !== this.selectedCompany.id)?.map(item => {
            return {
              label: item.companyName,
              value: item.id
            }
          })
          if (isGetAll) {
            this.transferToCompanyList = this.transferToCompanyOpst
          }
        } else {
          this.transferTocompanyOpst = []
        }
      } catch (e) {
        this.$message.error('Error retrieving list of companies')
      } finally {
        this.selectOpstSearchLoading = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.manager-container {
  display: flex;
  height: 100%;
  width: 100%;

  .left {
    box-sizing: border-box;
    height: 100%;
    width: 310px;
    margin-right: 5px;
    padding: 10px 0px 10px 10px;
    border: 1px solid #f2f6fc;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .content {
      margin-top: 10px;
      height: calc(90% - 50px);
      overflow-y: auto;
    }
  }
  .right {
    padding:0px 10px;
    box-sizing: border-box;
    border-left: 1px solid $primary-color-border;
    overflow-y: auto;
    width:100%
  }
}
.div-main-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  & > h1 {
    color: $primary-color-font;
    text-align: left;
    margin: 0;
    font-size: 20px;
    min-width: 180px;
  }
  .el-button {
    margin-left: auto;
  }
}
.div-main-action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.el-dialog-save-cargo-officer {
  /deep/.el-dialog__title {
    font-size: 22px;
    font-weight: bold;
    color: #4c565c;
  }

  /deep/.el-dialog__footer {
    display: flex;
    justify-content: center;
  }
}

.parent-container {
  height: 100%;
}
button.el-button.el-tooltip.transfer-to.is-circle {
    color: white;
    background:#0bf5de;
    border-color: #0bf5de;
    &:hover{
      background:#aef2ed;
      border-color:#aef2ed;
    }
}
button.el-button.el-tooltip.transfer-to.is-circle.is-disabled,button.el-button.el-tooltip.transfer-stop.is-circle.is-disabled{
    color: white;
    background:#C0C4CC;
    border-color:#EBEEF5;

}
button.el-button.el-tooltip.transfer-stop.is-circle {
    color: white;
    background:#FC7C05;
    border-color: #FC7C05;
     &:hover{
      background:#F9B06D;
      border-color:#F9B06D;
    }
}
button.el-button.el-tooltip.transfer-to.is-circle.is-disabled,
button.el-button.el-tooltip.transfer-stop.is-circle.is-disabled{
    color: white;
    background:#C0C4CC;
    border-color:#EBEEF5;

}
.transfer-to-from {
    display: flex;
    justify-items: center;
    justify-content: left;
  .arrow-icon{
    height: 100%;
    display: block;
    margin: auto 0;
    img{
      margin-top: 6px;
    }
  }
}

</style>
